<div class="modal-container">
	<div class="modal-body">
		<h3>Leave Feedback</h3>
		<p class="modal-description">
			Your experience matters to us. Share your feedback to help us enhance this tool to better serve your needs.
		</p>
	</div>
	<div class="message-area">
		<label class="feedback-label" for="feedback-text">Your Feedback</label>
		<textarea type="textarea" id="feedback-text" [(ngModel)]="message" class="form-control" maxlength="250" placeholder="Text"></textarea>
		<div class="character-count">{{ message.length }}/250</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="jbl-btn jbl-btn-md jbl-btn-secondary cancel-btn" (click)="dismiss()">Cancel</button>
		<button
			type="button"
			class="jbl-btn jbl-btn-md jbl-btn-primary continue-btn"
			(click)="sendFeedback()"
			(keyup.enter)="sendFeedback()"
			[disabled]="message === ''">
			Submit Feedback
		</button>
	</div>
</div>
