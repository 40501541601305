/* eslint-disable @typescript-eslint/no-explicit-any */
import { IServerSideGetRowsParams } from '@ag-grid-community/core';
import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { FEEDBACK_TOAST } from '@constants/feedback.constant';
import { MiRoutes } from '@core/configs/routes';
import { ConfigService } from '@core/services/config.service';
import { Quote, QuoteStatus, QuotesTableSearchParams, UserOwnershipInviteParams } from '@interfaces/qoute.interface';
import { JblToastService } from '@jbl-pip/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StateSignal, patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { setAllEntities, setEntity, updateEntity, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { FeedbackService } from '@services/feedback.service';
import { QuotesModalService } from '@services/quotes-modal.service';
import { Observable, combineLatest, filter, map, pipe, switchMap, tap, withLatestFrom } from 'rxjs';
import { QuoteWithAttributes } from 'src/app/features/dashboard/components/quotes-table/quotes-table.interface';
import { QuoteCreateParams, QuotesDataService } from '../services/quotes-data-service';

export const QuotesStore = signalStore(
	{ providedIn: 'root' },
	withEntities<Quote>(),
	withState({
		loading: false,
		currentQuoteId: '' as string
	}),
	withMethods((store: StateSignal<any>, quotesApi = inject(QuotesDataService), router = inject(Router)) => {
		const modalService = inject(QuotesModalService);
		const toastService = inject(JblToastService);
		const feedbackService = inject(FeedbackService);
		const configService = inject(ConfigService);
		let processingRef: NgbModalRef;

		return {
			setCurrentQuoteId(quoteId: string) {
				patchState(store, { currentQuoteId: quoteId });
			},
			setQuote(quote: Quote) {
				patchState(store, setEntity({ ...quote, id: quote.mi6_quote_id }));
			},
			updateLocalQuote(quote: Quote, value: Record<string, string>) {
				patchState(store, setEntity({ ...quote, ...value }));
			},
			getQuoteStatus(quoteId: string): Observable<QuoteStatus> {
				return quotesApi.getQuoteStatus(quoteId).pipe(map((res: Quote) => res.status));
			},
			openFeedbackModal() {
				combineLatest([toObservable(configService.currentUser), modalService.openFeedbackModal().closed])
					.pipe(
						filter(([, feedback]) => !!feedback),
						switchMap(([user, feedback]) => {
							return feedbackService.sendFeedback({
								authorRole: user ? user.roles.join(',') : 'Unknown role',
								authorEmail: user ? user.email : '',
								text: feedback
							});
						})
					)
					.subscribe(() => toastService.success(FEEDBACK_TOAST.FB_REQUEST_SUCCESS));
			},
			createQuote: rxMethod<QuoteCreateParams>(
				pipe(
					tap(() => {
						processingRef = modalService.openProcessingModal();
					}),
					switchMap(params => quotesApi.createQuote(params)),
					withLatestFrom(toObservable(configService.currentUser)),
					tap(([quote, currentUser]) => {
						// Move it out side when quotes list page will be implemented
						processingRef.close();

						configService.updateOwnershipLocally(quote.mi6_quote_id, [currentUser!.username], 'owns');
						router.navigateByUrl(`${MiRoutes.quotes.url}/` + quote.mi6_quote_id);
					})
				)
			),
			closeQuote: rxMethod<{ quoteId: string }>(
				pipe(
					switchMap(({ quoteId }) => quotesApi.closeQuote(quoteId)),
					tap((quote: Quote) => {
						patchState(store, updateEntity({ id: quote.mi6_quote_id, changes: quote }));
					})
				)
			),
			inviteOwners(usersData: UserOwnershipInviteParams[]) {
				return quotesApi.inviteOwnersToQuote((store as any).currentQuoteId(), usersData);
			},
			inviteContributors(usersData: UserOwnershipInviteParams[]) {
				return quotesApi.inviteContributorsToQuote((store as any).currentQuoteId(), usersData);
			},
			loadAllQuotes: rxMethod<void>(
				pipe(
					tap(() => patchState(store, { loading: true })),
					switchMap(() => quotesApi.getQuotes()),
					tap((quotes: any[]) => {
						const mapped = quotes.map(quote => {
							const scaIsRunning = quote.sca_run_status === 'Processing';

							return { ...quote, status: scaIsRunning ? quote.sca_run_status : quote.status };
						});

						patchState(store, setAllEntities(mapped));
					})
				)
			),
			loadAgQuotes: (params: IServerSideGetRowsParams<QuoteWithAttributes>, searchParams: QuotesTableSearchParams | null) => {
				return quotesApi.getAgQuotes(params, searchParams);
			},
			updateQuote: rxMethod<any>(
				pipe(
					switchMap(({ quoteId, quote }) => quotesApi.updateQuote(quoteId, quote)),
					tap((quote: Quote) => {
						patchState(store, updateEntity({ id: quote.mi6_quote_id, changes: quote }));
					})
				)
			)
		};
	})
);
