import { Injectable, inject } from '@angular/core';
import { SendFeedback } from '@graphql/feedback.queries';
import { GqlServerService } from '@services/gql-server.service';
import { FeedbackInputParams } from '../interfaces/feedback.interface';

@Injectable({ providedIn: 'root' })
export class FeedbackService {
	private readonly gql = inject(GqlServerService).main;

	sendFeedback(feedbackParams: FeedbackInputParams) {
		return this.gql.mutate({
			mutation: SendFeedback,
			variables: {
				feedbackParams
			}
		});
	}
}
