import { IServerSideGetRowsParams } from '@ag-grid-community/core';

interface AgGridFilterCondition {
	filterType: string;
	type?: string;
	dateFrom?: string | Date;
	dateTo?: string | Date;
	filter?: number | string;
	filterTo?: number | string;
	values?: (string | null)[] | string;
}

interface AgGridFilter extends AgGridFilterCondition {
	operator?: string;
	conditions?: AgGridFilterCondition[];
	condition1: unknown;
	condition2: unknown;
}

export function getMappedFilterModel<T>(params: IServerSideGetRowsParams<T>): Record<string, AgGridFilter> {
	const filterModel = structuredClone(params.request.filterModel) as Record<string, AgGridFilter>;

	for (const filter of Object.values(filterModel)) {
		const conditions = Array.isArray(filter.conditions) && filter.conditions.length > 0 ? filter.conditions : [filter];

		delete filter.condition1;
		delete filter.condition2;

		filter.operator = filter.operator?.toLowerCase();

		for (const condition of conditions) {
			condition.dateFrom =
				condition.dateFrom === undefined || condition.dateFrom === null
					? undefined
					: JSON.stringify(new Date(condition.dateFrom).toISOString());
			condition.dateTo =
				condition.dateTo === undefined || condition.dateTo === null ? undefined : JSON.stringify(new Date(condition.dateTo).toISOString());
			condition.filter = condition.filter === undefined ? undefined : JSON.stringify(condition.filter);
			condition.filterTo = condition.filterTo === undefined ? undefined : JSON.stringify(condition.filterTo);
			condition.values = condition.values === undefined ? undefined : JSON.stringify(condition.values);
		}
	}

	return filterModel;
}
