import { Injectable, inject } from '@angular/core';
import { FeedbackModalComponent } from '@components/modals/feedback-modal/feedback-modal.component';
import { JblModalProcessingComponent } from '@jbl-pip/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
	providedIn: 'root'
})
export class QuotesModalService {
	private readonly modalService = inject(NgbModal);

	openProcessingModal(): NgbModalRef {
		const modalRef = this.modalService.open(JblModalProcessingComponent, { backdrop: 'static' });
		modalRef.componentInstance.message = {
			header: 'Creating quote...',
			body: 'Please don’t refresh your browser or exit this page until the process is finished.'
		};

		return modalRef;
	}

	openFeedbackModal(): NgbModalRef {
		const modalRef = this.modalService.open(FeedbackModalComponent, { backdrop: 'static' });

		return modalRef;
	}
}
