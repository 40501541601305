import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'mi-feedback-modal',
	standalone: true,
	imports: [NgbModule, FormsModule],
	templateUrl: './feedback-modal.component.html',
	styleUrl: './feedback-modal.component.scss'
})
export class FeedbackModalComponent {
	activeModal = inject(NgbActiveModal);
	message = '';

	dismiss(): void {
		this.activeModal.dismiss();
	}

	sendFeedback(): void {
		this.activeModal.close(this.message);
	}
}
