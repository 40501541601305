import { Directive, TemplateRef, ViewContainerRef, effect, inject } from '@angular/core';
import { QuotesStore } from '@stores/quotes.store';
import { ConfigService } from '../config.service';
import { CerbosResource, QuoteActions } from './cerbos.constants';
import { CerbosValidationRequest } from './cerbos.interface';
import { CerbosService } from './cerbos.service';

@Directive()
export abstract class CerbosDirective {
	protected abstract action: QuoteActions;

	protected readonly configService = inject(ConfigService);
	protected readonly cerbosService = inject(CerbosService);
	protected readonly templateRef = inject(TemplateRef);
	protected readonly viewContainer = inject(ViewContainerRef);
	readonly quotesStore = inject(QuotesStore);

	checker = effect(() => {
		const quoteId = this.quotesStore.currentQuoteId();
		const currentUser = this.configService.currentUser();
		const request = {
			principal: {
				id: currentUser?.username,
				roles: currentUser?.roles,
				attr: {
					ownership: currentUser?.ownership
				}
			},
			resource: {
				kind: CerbosResource.Quote,
				attr: { quoteId }
			}
		} as CerbosValidationRequest;

		this.check(request);
	});

	check(request: CerbosValidationRequest) {
		this.viewContainer.clear();

		const allowed = this.cerbosService.checkResource(request).isAllowed(this.action);

		console.info(
			`Permission check for role: ${request?.principal.roles[0]}; Action: [${this.action}]; QuoteID: ${this.quotesStore.currentQuoteId()}; Allowed: ${allowed}`
		);

		if (allowed) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		}
	}
}

@Directive({ selector: '[ifAllowOwnersInvites]', standalone: true })
export class IfAllowOwnersInvitesDirective extends CerbosDirective {
	action = QuoteActions.InviteOwners;
}

@Directive({ selector: '[ifAllowContributorsInvites]', standalone: true })
export class IfAllowContributorsInvitesDirective extends CerbosDirective {
	action = QuoteActions.InviteContributors;
}

@Directive({ selector: '[ifAllowAddQuotePart]', standalone: true })
export class IfAllowAddQuotePartDirective extends CerbosDirective {
	action = QuoteActions.AddPart;
}

@Directive({ selector: '[ifAllowRequestApriori]', standalone: true })
export class IfAllowRequestAprioriDirective extends CerbosDirective {
	action = QuoteActions.RequestAprioriEstimate;
}

@Directive({ selector: '[ifAllowRequestManualEstimate]', standalone: true })
export class IfAllowRequestManualEstimateDirective extends CerbosDirective {
	action = QuoteActions.AddManualEstimate;
}

@Directive({ selector: '[ifAllowSearchWithinQuotes]', standalone: true })
export class IfAllowSearchWithinQuotesDirective extends CerbosDirective {
	action = QuoteActions.Search;
}

@Directive({ selector: '[ifAllowSaveAndRunSca]', standalone: true })
export class IfAllowSaveAndRunScaDirective extends CerbosDirective {
	action = QuoteActions.SaveAndRunSca;
}

@Directive({ selector: '[ifAllowQuoteClosing]', standalone: true })
export class IfAllowQuoteClosingDirective extends CerbosDirective {
	action = QuoteActions.Close;
}

@Directive({ selector: '[ifAllowAssignQuantity]', standalone: true })
export class IfAllowAssignQuantityDirective extends CerbosDirective {
	action = QuoteActions.AssignQuantity;
}

@Directive({ selector: '[ifAllowAssignRegion]', standalone: true })
export class IfAllowAssignRegionDirective extends CerbosDirective {
	action = QuoteActions.AssignRegion;
}

@Directive({ selector: '[ifAllowQuoteEdit]', standalone: true })
export class IfAllowQuoteEditDirective extends CerbosDirective {
	action = QuoteActions.Edit;
}

@Directive({ selector: '[ifAllowVerifyEstimate]', standalone: true })
export class IfAllowVerifyEstimateDirective extends CerbosDirective {
	action = QuoteActions.VerifyEstimate;
}

@Directive({ selector: '[ifAllowUndoEstimateVerification]', standalone: true })
export class IfAllowUndoEstimateVerificationDirective extends CerbosDirective {
	action = QuoteActions.UndoEstimateVerification;
}
