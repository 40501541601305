/* eslint-disable @typescript-eslint/no-explicit-any */
import 'reflect-metadata';

import { Injectable } from '@angular/core';
import { CerbosEffect } from './cerbos.constants';
import { CerbosValidationRequest } from './cerbos.interface';
import { CerbosPolicies } from './cerbos.policies';

@Injectable({
	providedIn: 'root'
})
export class CerbosService {
	private readonly policies = CerbosPolicies;

	// cerbos interface was followed here
	checkResource(request: CerbosValidationRequest) {
		return {
			isAllowed: (action: any) => {
				return this.policies.some(p => this.evaluate_single_policy_permissions(request, p).isAllowed(action));
			}
		};
	}

	private evaluate_single_policy_permissions(request: CerbosValidationRequest, policy: any) {
		// Extract relevant information from the request
		const { principal, resource } = request;

		// Function to check if an action is allowed for a given resource
		function checkPermission(resource: any, action: any) {
			if (resource.kind !== policy.resourcePolicy.resource) {
				return false;
			}

			for (const rule of policy.resourcePolicy.rules) {
				if (rule.actions.includes(action) && rule.roles.some((role: any) => principal.roles.includes(role))) {
					const allowed = rule.effect === CerbosEffect.Allow;

					if (rule.condition) {
						return allowed && rule.condition(request);
					} else {
						return allowed;
					}
				}
			}

			return false;
		}

		// Return an object with isAllowed function
		return {
			isAllowed: (action: string) => checkPermission(resource, action)
		};
	}
}
